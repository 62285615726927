import { useEffect, useState } from "react";
import { BASE_URL } from "../constant";

const useCategoryFetch = (canteenId) => {
  const [loading, setLoading] = useState(false);
  const [categoryData, setCategoryData] = useState([]);

  useEffect(() => {
    setLoading(true);

    let formData = new FormData();
    formData.append("canteen_id", canteenId);

    let requestOptions = {
      method: "POST",
      body: formData,
    };

    fetch(`${BASE_URL}get_canteen_category`, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        setCategoryData(result.data);
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
      });
  }, [canteenId]);
  return [loading, categoryData];
};

export default useCategoryFetch;
