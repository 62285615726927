import { Link, useParams } from "react-router-dom";
import React, { useEffect, useRef, useState } from "react";
import moment from "moment";
import { BASE_URL } from "../constant";
import useAbhhyamTransactionFetch from "../hooks/useAbhhyamTransactionFetch";
import AbhhyamInvoice from "../modals/AbhhyamInvoice";
import { INVOICE_DATA } from "../constant";
import { useNavigate } from "react-router-dom";

const TabAbhhyamTransactions = () => {
  const navigate = useNavigate();

  const routeParams = useParams();
  const canteenId = routeParams.canteenId;
  const scrollableDivRef = useRef(null);
  const [data, setData] = useState([]); // Your data array or object
  const [searchQuery, setSearchQuery] = useState("");
  const [page, setPage] = useState(0);
  const [transactionLoading, transactionData] = useAbhhyamTransactionFetch(
    page,
    searchQuery,
    canteenId
  );

  const handleSearch = (event) => {
    setSearchQuery(event.target.value);
  };

  useEffect(() => {
    setData(transactionData);
  }, [transactionData]);

  const handleScroll = () => {
    const { scrollTop, clientHeight, scrollHeight } = scrollableDivRef.current;
    const bottomReached = scrollTop + clientHeight === scrollHeight;

    if (bottomReached) {
      // You can trigger an alert here or perform any other action you want
      setPage(page + 20);
    }
  };

  const generateInvoice = async (invoiceId, customerNumber) => {
    try {
      let myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");

      let raw = JSON.stringify({
        orderId: invoiceId,
      });

      let requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
      };

      const response = await fetch(
        `${BASE_URL}get_abhhyam_invoice_details`,
        requestOptions
      );
      const newData = await response.json();

      const invoiceData = {
        orders: newData.invoice_data.orders,
        orderItem: newData.invoice_data.orderItem,
        vendor: newData.invoice_data.vendor,
      };

      localStorage.removeItem(INVOICE_DATA);
      localStorage.setItem(INVOICE_DATA, JSON.stringify(invoiceData));

      navigate(`/abhhyamInvoice/${canteenId}/${customerNumber}/${invoiceId}`);
    } catch (e) {
      console.log(e);
    }
  };

  return (
    <div>
      <Link to={"/?canteenId=" + canteenId}>
        <div className={"flex items-center"}>
          <i className={"fa fa-arrow-left"}></i>
          &nbsp;
          <p className={"text-lg font-bold text-orange-500"}>Transactions</p>
        </div>
      </Link>
      <div className="flex flex-row h-screen antialiased text-blue-gray-800">
        <div className="flex-grow flex">
          <div className="flex flex-col bg-blue-gray-50 w-full py-4">
            <div className="grid grid-cols-1">
              <div className="flex px-2 flex-row relative justify-between mb-2">
                <div className="absolute left-3 px-2 py-1 top-1 rounded-full bg-orange-500 text-white">
                  <i className={"fa fa-search"}></i>
                </div>
                <input
                  type="text"
                  className="bg-white rounded-full shadow text-sm full w-full h-10 py-4 pl-16 transition-shadow focus:shadow-2xl focus:outline-none"
                  placeholder="Search By Customer Number and Invoice Id"
                  onChange={handleSearch}
                  value={searchQuery}
                />
                {searchQuery ? (
                  <div
                    className="absolute right-3 px-2 py-1 top-1"
                    onClick={() => setSearchQuery("")}
                  >
                    <i className={"fa fa-circle-xmark"}></i>
                  </div>
                ) : null}
              </div>
            </div>
            <div className={"container"}>
              <div className={"row"}></div>
            </div>
            <div className="overflow-hidden mt-4">
              <div
                className="h-full overflow-y-auto px-2"
                ref={scrollableDivRef}
                onScroll={handleScroll}
              >
                <div className="grid grid-cols-1 pb-3">
                  {data.map((val, index) => {
                    return (
                      <div
                        className="cursor-pointer transition-shadow overflow-hidden border-2 bg-white shadow hover:shadow-lg p-2"
                        key={index}
                        onClick={() => generateInvoice(val.id, val.phone)}
                      >
                        <div className="flex justify-between">
                          <p className={"font-bold text-xs"}>
                            {index + 1}) Order created successfully
                          </p>
                          <p className={"font-bold text-xs"}>Rs {val.amount}</p>
                        </div>
                        <div>
                          <div className="flex justify-between">
                            <p className={"text-xs font-bold"}>
                              {moment(val.created_at).format("lll")}
                            </p>
                            <p className={"font-bold text-xs uppercase"}>
                              Payment Mode : {val.method}
                            </p>
                          </div>
                          <p className={"text-xs font-bold"}>
                            Invoice No. : {`AVI${val.sr_no}`}
                          </p>
                          <p className={"text-xs font-bold"}>
                            Customer Number : {val.phone}
                          </p>
                          <button
                            className="text-center px-2 bg-sky-400 font-bold text-white focus:outline-none hover:bg-orange-400 mt-2"
                            onClick={() => generateInvoice(val.id, val.phone)}
                          >
                            Generate Invoice
                          </button>
                        </div>
                      </div>
                    );
                  })}
                </div>

                {data.length === 0 ? (
                  <div className="select-none bg-blue-gray-100 rounded-3xl flex-wrap content-center justify-center p-10">
                    <div className="w-full text-center">
                      <i className={"fa fa-database fa-4x"}></i>
                      <p className="text-xl font-bold mt-2">
                        TRANSACTIONS NOT FOUND
                      </p>
                    </div>
                  </div>
                ) : null}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default TabAbhhyamTransactions;
