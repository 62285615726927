import { useParams } from "react-router-dom";
import React, { useCallback, useEffect, useRef, useState } from "react";
import Invoice from "../modals/Invoice";
import { INVOICE_DATA } from "../constant";

const CanteenInvoice = () => {
  const routeParams = useParams();
  const canteenId = routeParams.canteenId;

  const [invoiceData, setInvoiceData] = useState(null);

  const getInvoiceData = useCallback(()=>{
    const data = localStorage.getItem(INVOICE_DATA)
    const parseJson = JSON.parse(data)
  
    setInvoiceData(parseJson)
  },[])

  useEffect(()=>{
    getInvoiceData()
  },[getInvoiceData])

  
 if(invoiceData!==null){
  return (
    <Invoice
      invoiceData={invoiceData}
      canteenId={canteenId}
    />
  );
 }
};
export default CanteenInvoice;
