import { useCallback, useEffect, useState } from "react";
import { BASE_URL } from "../constant";

const useTransactionFetch = (page, searchQuery, canteenId) => {
  const [transactionLoading, setTransactionLoading] = useState(false);
  const [transactionData, setTransactionData] = useState([]);

  const getTransactionsData = useCallback(async () => {
    setTransactionLoading(true);

    let myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    let formData = JSON.stringify({
      canteenId: canteenId,
      search: searchQuery,
      limit: 30,
      next: page,
    });

    let requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: formData,
      redirect: "follow",
    };

    const response = await fetch(`${BASE_URL}get_invoice_list`, requestOptions);
    const newData = await response.json();

    if (page > 0) {
      if (newData.data.length > 0) {
        setTransactionData((prevData) => [...prevData, ...newData.data]);
      }
    } else {
      setTransactionData(newData.data);
    }
    setTransactionLoading(false);
  }, [page, searchQuery, canteenId]);

  useEffect(() => {
    getTransactionsData();
  }, [getTransactionsData]);

  return [transactionLoading, transactionData];
};

export default useTransactionFetch;
