import "../App.css";
import "../style.css";
import React, { useCallback, useEffect, useRef, useState } from "react";
import useCategoryFetch from "../hooks/useCategoryFetch";
import useProductFetch from "../hooks/useProductFetch";
import { BASE_URL, CART_DATA, IMAGE_URL } from "../constant";
import addSound from "../sound/add-sound.mp3";
import errorSound from "../sound/error.wav";
import useSound from "use-sound";
import TabCartComponent from "./TabCartComponent";
import BarcodeScannerComponent from "react-qr-barcode-scanner";
import { ErrorToast } from "../loader/Toast";
import { useNavigate } from "react-router-dom";

function TabHome() {
  const navigate = useNavigate();

  const queryParams = new URLSearchParams(window.location.search);
  const canteenId = queryParams.get("canteenId");
  const barcode = queryParams.get("barcode");

  const [playAddSound] = useSound(addSound);
  const [playErrorSound] = useSound(errorSound);

  const scrollableDivRef = useRef(null);

  const [data, setData] = useState([]); // Your data array or object
  const [searchQuery, setSearchQuery] = useState("");
  const [searchBarCode, setSearchBarCode] = useState("");
  const [cartData, setCartData] = useState([]);

  const [searchType, setSearchType] = useState("products");
  const [itemCount, setItemCount] = useState(0);
  const [page, setPage] = useState(0);
  const [selectCategory, setSelectCategory] = useState("all");
  const [accountType, setAccountType] = useState("canteen");
  const [scanner, setScanner] = useState(false);
  const [holdOrderId, setHoldOrderId] = useState(0);
  const [loading, categoryData] = useCategoryFetch(canteenId);
  const [productLoading, productData] = useProductFetch(
    page,
    selectCategory,
    searchType === "barcode" ? searchBarCode : searchQuery,
    canteenId,
    searchType
  );
  const [canteenDetails, setCanteenDetails] = useState("");
  const [members, setMembers] = useState([]);

  const handleSearch = (event) => {
    setSearchQuery(event.target.value);
  };

  const handleSearchBarcode = (event) => {
    setSearchBarCode(event.target.value);
    setSearchType("barcode");
  };

  const getBarcodeProduct = async (barcode) => {
    let formData = new FormData();
    formData.append("canteen_id", canteenId);
    formData.append("barcode", barcode);

    let requestOptions = {
      method: "POST",
      body: formData,
    };

    const response = await fetch(
      `${BASE_URL}get_inventory_by_barcode`,
      requestOptions
    );
    const newData = await response.json();
    await addToCart(newData.data, "increase");
    setScanner(false);
  };

  const getCanteenDetails = useCallback(async () => {
    let formData = new FormData();
    formData.append("canteen_id", canteenId);

    let requestOptions = {
      method: "POST",
      body: formData,
      redirect: "follow",
    };

    fetch(`${BASE_URL}get_canteen_wallet`, requestOptions)
      .then((response) => response.json())
      .then((result) => setCanteenDetails(result.data))
      .catch((error) => console.log("error", error));
  }, [canteenId]);

  const getMembers = useCallback(async () => {
    let requestOptions = {
      method: "POST",
    };

    const response = await fetch(
      `${BASE_URL}get_abhhyam_member`,
      requestOptions
    );
    const newData = await response.json();
    setMembers(newData.data);
  }, []);

  useEffect(() => {
    getCanteenDetails();
    getMembers();
  }, [getCanteenDetails, getMembers]);

  useEffect(() => {
    if (barcode) {
      getBarcodeProduct(barcode);
    }
  }, [barcode]);

  const addToCart = useCallback(
    (value, operator) => {
      const prevCartData = [...cartData];

      const findIndex = prevCartData.findIndex((d) => d.id === value.id);
      if (findIndex > -1) {
        if (operator === "increase") {
          // if (accountType === "canteen") {
          if (value.stock > prevCartData[findIndex].quantity) {
            prevCartData[findIndex].quantity =
              prevCartData[findIndex].quantity + 1;
            playAddSound();
          } else {
            playErrorSound();
            ErrorToast(`Stock not available`);
          }
          // } else {
          //   prevCartData[findIndex].quantity =
          //     prevCartData[findIndex].quantity + 1;
          //   playAddSound();
          // }
        } else if (operator === "decrease") {
          if (prevCartData[findIndex].quantity === 1) {
            prevCartData.splice(findIndex, 1);
          } else {
            prevCartData[findIndex].quantity =
              prevCartData[findIndex].quantity - 1;
          }
        } else {
          prevCartData.splice(findIndex, 1);
        }
        setCartData(prevCartData);
      } else {
        // if (accountType === "canteen") {
        if (value.stock > 0) {
          value.quantity = 1;
          setCartData([...prevCartData, value]);
          playAddSound();
        } else {
          playErrorSound();
          ErrorToast(`Stock not available`);
        }
        // } else {
        //   playAddSound();
        //   value.quantity = 1;
        //   setCartData([...prevCartData, value]);
        // }
      }

      setItemCount((prevItemCount) => prevItemCount + 1);
    },
    [accountType, cartData, playAddSound, playErrorSound]
  );

  useEffect(() => {
    setData(productData);
  }, [productData]);

  const handleScroll = () => {
    const { scrollTop, clientHeight, scrollHeight } = scrollableDivRef.current;
    const bottomReached = scrollTop + clientHeight >= scrollHeight;

    if (bottomReached) {
      // You can trigger an alert here or perform any other action you want
      setPage(page + 20);
    }
  };

  useEffect(() => {
    const storeLocalData = localStorage.getItem(CART_DATA);
    let item = 0;
    if (storeLocalData) {
      const parseData = JSON.parse(storeLocalData);
      const cartStoreData = parseData.value;
      setCartData(cartStoreData);
      setHoldOrderId(parseData.orderId);
      cartStoreData.map((cData) => {
        item += cData.quantity;
      });
      setItemCount(item);
    }
  }, []);

  if (scanner) {
    return (
      <div>
        <div
          className="absolute right-3 px-2 py-1 top-1 z-40"
          onClick={() => setScanner(false)}
        >
          <i className={"fa fa-circle-xmark fa-2x text-white"}></i>
        </div>
        <BarcodeScannerComponent
          onUpdate={(err, result) => {
            if (result) {
              getBarcodeProduct(result.text);
              setScanner(false);
            }
          }}
        />
      </div>
    );
  }

  return (
    <div className={"text-blue-gray-800"}>
      <div className="flex flex-row h-screen antialiased text-blue-gray-800">
        <div className="flex-grow flex">
          <div className="flex flex-col bg-blue-gray-50 w-full py-4">
            <div className="grid grid-cols-11">
              <div className="flex px-2 flex-row relative mb-2 col-span-6">
                <div className="absolute left-3 px-2 py-1 top-1 rounded-full bg-orange-500 text-white">
                  <i className={"fa fa-search"}></i>
                </div>
                <input
                  type="text"
                  className="bg-white rounded-full shadow text-sm full w-full h-10 py-4 pl-16 transition-shadow focus:shadow-2xl focus:outline-none"
                  placeholder="Search products by name and product code"
                  onChange={handleSearch}
                  value={searchQuery}
                />
                {searchQuery ? (
                  <div
                    className="absolute right-3 px-2 py-1 top-1"
                    onClick={() => setSearchQuery("")}
                  >
                    <i className={"fa fa-circle-xmark"}></i>
                  </div>
                ) : null}
              </div>
              <div className="flex px-2 flex-row relative justify-end mb-2 col-span-4">
                <div className="absolute left-3 px-2 py-1 top-1 rounded-full bg-orange-500 text-white">
                  <i className={"fa fa-search"}></i>
                </div>
                <input
                  type="text"
                  className="bg-white rounded-full shadow text-sm full w-full h-10 py-4 pl-16 transition-shadow focus:shadow-2xl focus:outline-none"
                  placeholder="Search by bar code"
                  onChange={handleSearchBarcode}
                  value={searchBarCode}
                />
                {searchBarCode ? (
                  <div
                    className="absolute right-3 px-2 py-1 top-1"
                    onClick={() => setSearchBarCode("")}
                  >
                    <i className={"fa fa-circle-xmark"}></i>
                  </div>
                ) : null}
              </div>
              <div
                className="flex px-2 justify-end"
                onClick={() => navigate("/scanner/" + canteenId)}
              >
                <img
                  src="https://cdn-icons-png.flaticon.com/512/5393/5393325.png"
                  alt=""
                  className={"barcode-size"}
                />
              </div>
            </div>
            <div className="grid grid-cols-4 gap-1">
              <div
                onClick={() => {
                  setSelectCategory("all");
                  setPage(0);
                }}
                className={`${
                  selectCategory === "all" ? "bg-orange-500" : "bg-gray-300"
                } mx-2 rounded-lg text-center p-2 self-center text-white cursor-pointer`}
              >
                All Category
              </div>
              {categoryData.map((cate, index) => {
                return (
                  <div
                    onClick={() => {
                      setSelectCategory(cate.id);
                      setPage(0);
                    }}
                    key={index}
                    className={`${
                      selectCategory === cate.id
                        ? "bg-orange-500"
                        : "bg-gray-300"
                    } mx-2 rounded-lg text-center p-2 self-center text-white cursor-pointer`}
                  >
                    {cate.name}
                  </div>
                );
              })}
            </div>
            <div className={"container"}>
              <div className={"row"}></div>
            </div>
            <div className="overflow-hidden mt-4">
              <div
                className="h-full overflow-y-auto px-2"
                ref={scrollableDivRef}
                onScroll={handleScroll}
              >
                <div className="grid grid-cols-3 pb-3">
                  {data.map((val, index) => {
                    return (
                      <div
                        className="cursor-pointer transition-shadow overflow-hidden border-2 bg-white shadow hover:shadow-lg"
                        key={index}
                        onClick={() => addToCart(val, "increase")}
                      >
                        <div className="flex pb-3 justify-between">
                          <p
                            className={
                              "line-through text-red-600 font-bold text-xs"
                            }
                          >
                            Rs. {val.sell_price}
                          </p>
                          <p className={"text-green-600 font-bold text-xs"}>
                            Rs.
                            {accountType === "abhhyam" ? val.dp_price : val.csp}
                          </p>
                          {accountType === "abhhyam" ? (
                            <p className="font-bold text-xs text-white bg-orange-600 px-1">
                              PV : {val.pv}
                            </p>
                          ) : null}
                        </div>
                        <center>
                          <img
                            src={IMAGE_URL + val.image}
                            alt={val.product_name}
                            className={"img-size"}
                            onError={({ currentTarget }) => {
                              currentTarget.onerror = null; // prevents looping
                              currentTarget.src =
                                "https://ardhsainikcanteen.in/public/assets/website/images/asc_logo.jpg";
                            }}
                          />
                          {/* {accountType === "canteen" ? ( */}
                          <p
                            className={`mx-2 text-xs font-bold ${
                              val.stock > 0 ? "text-green-700" : "text-red-600"
                            }`}
                          >
                            Stock : {val.stock}
                          </p>
                          {/* ) : null} */}
                          <p className={"mx-2 text-xs font-bold"}>
                            {val.product_name}
                          </p>
                          <p
                            className={
                              "mx-2 mb-0 text-xs font-bold text-green-500"
                            }
                          >
                            {val.product_code}
                          </p>
                          <p
                            className={
                              "mx-2 mb-0 text-xs font-bold text-red-500"
                            }
                          >
                            <i className={"fa fa-barcode"}></i> {val.barcode}
                          </p>
                        </center>
                      </div>
                    );
                  })}
                </div>

                {data.length === 0 ? (
                  <div className="select-none bg-blue-gray-100 rounded-3xl flex-wrap content-center justify-center p-10">
                    <div className="w-full text-center">
                      <i className={"fa fa-database fa-4x"}></i>
                      <p className="text-xl font-bold mt-2">
                        PRODUCT NOT FOUND
                      </p>
                    </div>
                  </div>
                ) : null}
              </div>
            </div>
          </div>
          <TabCartComponent
            cartData={cartData}
            setCartData={setCartData}
            itemCount={itemCount}
            setItemCount={setItemCount}
            setAccountType={setAccountType}
            accountType={accountType}
            canteenId={canteenId}
            holdOrderId={holdOrderId}
            canteenDetails={canteenDetails}
            members={members}
          />
        </div>
      </div>
    </div>
  );
}

export default TabHome;
