import React, { useState } from "react";
import { BASE_URL, CART_DATA } from "../constant";
import toast from "react-hot-toast";
import { LoaderToast, SuccessToast } from "../loader/Toast";

const HoldOrder = ({
  holdOrderModal,
  setHoldOrderModal,
  cartData,
  items,
  pv,
  subTotal,
  discount,
  tax,
  grandTotal,
  accountType,
  setCartData,
  canteenId,
  holdOrderId,
}) => {
  const [mobileNumber, setMobileNumber] = useState("");
  const [loader, setLoader] = useState(false);

  const submitHoldOrder = async (e) => {
    e.preventDefault();
    setLoader(true);
    LoaderToast("Order Processing");
    let myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    let raw = JSON.stringify({
      products: cartData,
      total_item: items,
      total_pv: pv,
      sub_total: subTotal,
      discount: discount,
      tax: tax,
      grandTotal: grandTotal.toFixed(2),
      accountType: accountType,
      customer_number: mobileNumber,
      canteen_id: canteenId,
      id: holdOrderId,
    });

    let requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    const response = await fetch(
      `${BASE_URL}hold-order-generate`,
      requestOptions
    );
    const result = await response.json();

    if (result.status) {
      setCartData([]);
      localStorage.removeItem(CART_DATA);
      setLoader(false);
      setHoldOrderModal(false);
      setMobileNumber("");
      toast.dismiss();
      SuccessToast("This order is on hold successfully.");
      window.location.reload();
    }
    setLoader(false);
  };

  return (
    <div
      className="fixed glass w-full h-screen left-0 top-0 z-10 flex flex-wrap justify-center content-center"
      style={{ display: holdOrderModal ? "flex" : "none" }}
    >
      <div className="w-96 bg-white shadow-xl">
        <div className={"p-4"}>
          <div className={"flex justify-end"}>
            <i
              className={"fa fa-close"}
              onClick={() => setHoldOrderModal(false)}
            ></i>
          </div>
          <form onSubmit={submitHoldOrder}>
            <div className={"mt-4 mb-4"}>
              <label htmlFor="">Mobile Number</label>
              <input
                type="number"
                className="border-2 w-full h-10 p-2 rounded-sm focus:outline-none"
                placeholder="Enter Mobile Number"
                onChange={(event) => setMobileNumber(event.target.value)}
                value={mobileNumber}
                min={0}
                required={true}
              />
            </div>

            <div>
              <button
                className="text-center w-full bg-green-500 text-white focus:outline-none hover:bg-green-600 p-2 disabled:opacity-25"
                disabled={loader}
              >
                SAVE
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default HoldOrder;
