import React, { useCallback, useEffect, useState } from "react";
import { BASE_URL, CART_DATA } from "../constant";
import toast from "react-hot-toast";
import { ErrorToast, LoaderToast, SuccessToast } from "../loader/Toast";
import CreatableSelect from "react-select/creatable";
import AbhhyamInvoice from "./AbhhyamInvoice";
import { INVOICE_DATA } from "../constant";
import { useNavigate } from "react-router-dom";

const AbhhyamProceedOrder = ({
  paymentModal,
  setPaymentModal,
  cartData,
  tax,
  grandTotal,
  accountType,
  setCartData,
  canteenId,
  members,
  canteenDetails,
  holdOrderId,
}) => {
  const navigate = useNavigate();

  const [receivedAmount, setReceivedAmount] = useState("");
  const [payingAmount, setPayingAmount] = useState("");
  const [paymentType, setPaymentType] = useState("");
  const [paymentNote, setPaymentNote] = useState("");
  const [balance, setBalance] = useState(0.0);
  const [customerType, setCustomerType] = useState("");
  const [customers, setCustomers] = useState("");
  const [name, setName] = useState("");
  const [password, setPassword] = useState("");
  const [email, setEmail] = useState("");
  const [mobileNumber, setMobileNumber] = useState("");
  const [address, setAddress] = useState("");
  const [state, setState] = useState("");
  const [city, setCity] = useState("");
  const [pincode, setPincode] = useState("");
  const [guideId, setGuideId] = useState("");
  const [search, setSearch] = useState("");
  const [membersData, setMembersData] = useState([]);
  const [searchLoader, setSearchLoader] = useState(false);

  const [loader, setLoader] = useState(false);

  const handleOnSearch = async (event) => {
    const inputValue = event.target.value;
    setSearch(inputValue);

    try {
      setSearchLoader(true);
      let formData = new FormData();
      formData.append("keyword", inputValue);

      let requestOptions = {
        method: "POST",
        body: formData,
      };

      const response = await fetch(
        `${BASE_URL}get_abhhyam_member_list`,
        requestOptions
      );

      const data = await response.json();
      setMembersData(data.data); // Update results state with fetched data
      setSearchLoader(false);
    } catch (error) {
      setSearchLoader(false);
      console.error("Error fetching data:", error);
    }
  };

  const submitProceedOrder = async (e) => {
    e.preventDefault();
    try {
      setLoader(true);
      LoaderToast("Order Processing");
      let myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");

      let bodyData = [];
      cartData.map((cart) => {
        bodyData.push({
          product_id: cart.product_id,
          id: cart.product_id,
          qty: cart.quantity,
          discount: cart.discount,
          price_sell: cart.dp_price,
          canteen_csp: cart.csp,
          mrp: cart.sell_price,
          tax_rate: cart.tax_rate,
          canteen_id: canteenId,
          inv_id: cart.inv_id,
          local_status: 0,
          pv: cart.pv,
          msp: cart.msp,
          vendor_pv: cart.vendor_pv,
        });
      });

      let raw = JSON.stringify({
        total_amount: grandTotal.toFixed(2),
        invoiceType: customerType === "new" ? "new" : "old",
        paid_by: paymentType.value,
        username: customers,
        name: name,
        password: password,
        email: email,
        state: state,
        city: city,
        address: address,
        parent: guideId,
        phone: mobileNumber,
        pincode: pincode,
        canteen_id: canteenId,
        payment_note: paymentNote,
        products_info: bodyData,
        holdOrderId: holdOrderId,
      });

      let requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
      };

      const response = await fetch(
        `${BASE_URL}abhhyam_invoice`,
        requestOptions
      );
      const result = await response.json();

      if (result.status) {
        setCartData([]);
        setMobileNumber("");
        setReceivedAmount("");
        setPayingAmount("");
        setPaymentType("");
        setPaymentNote("");
        setLoader(false);
        setPaymentModal(false);
        localStorage.removeItem(CART_DATA);
        const invoiceData = {
          orders: result.invoice_data.orders,
          orderItem: result.invoice_data.orderItem,
          vendor: result.invoice_data.vendor,
        };
        toast.dismiss();
        SuccessToast("Order created successfully.");
        localStorage.removeItem(INVOICE_DATA);
        localStorage.setItem(INVOICE_DATA, JSON.stringify(invoiceData));

        navigate(
          `/abhhyamInvoice/${canteenId}/${result.invoice_data.orders.phone}/${result.invoice_data.orders.id}`
        );
      } else {
        toast.dismiss();
        ErrorToast(result.msg);
        setLoader(false);
      }
    } catch (e) {
      ErrorToast(e.message);
      setLoader(false);
    }
  };

  const paymentOptions = [
    { value: "cash", label: "Cash" },
    { value: "upi", label: "UPI" },
    { value: "netbanking", label: "Net Banking" },
    { value: "debit/credit", label: "Debit/Credit" },
    { value: "wallet", label: "Wallet" },
  ];

  const userOptions = members.map((data) => {
    return {
      value: data.username,
      label: data.username,
    };
  });

  useEffect(() => {
    setReceivedAmount(grandTotal.toFixed(2));
    setPayingAmount(grandTotal.toFixed(2));
  }, [grandTotal, tax]);

  const remainingBalance = (value) => {
    setReceivedAmount(value);
    const amt = value - payingAmount;
    setBalance(amt.toFixed(2));
  };

  const handleSelect = (item) => {
    setSearch(`${item.name} (${item.username})`);
    setCustomers(item.username);
    setMembersData([]);
  };

  console.log({ search });
  console.log({ customers });

  return (
    <>
      <div
        className="fixed glass w-full h-screen left-0 top-0 z-10 flex flex-wrap justify-center content-center"
        style={{ display: paymentModal ? "flex" : "none" }}
      >
        <div className="w-192 bg-white shadow-xl h-full overflow-y-auto">
          <div className="text-center border-b-2 p-2 flex justify-between align-middle">
            <h3 className="text-center text-xl">Payment Summary</h3>
            <i
              className={"fa fa-close fa-2x"}
              onClick={() => setPaymentModal(false)}
            ></i>
          </div>

          <form onSubmit={submitProceedOrder}>
            <div className={"p-4"}>
              <p className={"text-green-700 font-bold"}>
                Wallet Amount : Rs. {canteenDetails.wallet}
              </p>
              <div className={"grid grid-cols-2 gap-2"}>
                <div className={"mt-4"}>
                  <input
                    type="radio"
                    id={"new"}
                    required={true}
                    name={"customer"}
                    onChange={() => setCustomerType("new")}
                    value={customerType}
                  />
                  &nbsp;
                  <label htmlFor="new">New Customer</label>
                </div>
                <div className={"mt-4"}>
                  <input
                    type="radio"
                    id={"already"}
                    required={true}
                    name={"customer"}
                    onChange={() => setCustomerType("already")}
                    value={customerType}
                  />
                  &nbsp;
                  <label htmlFor="already">Already Customer</label>
                </div>
              </div>

              {customerType === "new" ? (
                <div>
                  <div className={"grid grid-cols-2 gap-2"}>
                    <div className={"mt-4"}>
                      <label htmlFor="">
                        Guide Id <small className={"text-red-500"}>*</small>
                      </label>
                      <input
                        type="text"
                        className="border-2 w-full h-10 p-2 rounded-sm focus:outline-none"
                        placeholder="Enter Guide Id"
                        onChange={(event) => setGuideId(event.target.value)}
                        value={guideId}
                        required={true}
                      />
                    </div>
                    <div className={"mt-4"}>
                      <label htmlFor="">
                        Full Name <small className={"text-red-500"}>*</small>
                      </label>
                      <input
                        type="text"
                        className="border-2 w-full h-10 p-2 rounded-sm focus:outline-none"
                        placeholder="Enter Full Name"
                        onChange={(event) => setName(event.target.value)}
                        value={name}
                        required={true}
                      />
                    </div>
                  </div>
                  <div className={"grid grid-cols-2 gap-2"}>
                    <div className={"mt-4"}>
                      <label htmlFor="">
                        Password <small className={"text-red-500"}>*</small>
                      </label>
                      <input
                        type="text"
                        className="border-2 w-full h-10 p-2 rounded-sm focus:outline-none"
                        placeholder="Enter Password"
                        onChange={(event) => setPassword(event.target.value)}
                        value={password}
                        required={true}
                      />
                    </div>

                    <div className={"mt-4"}>
                      <label htmlFor="">Email (Optional)</label>
                      <input
                        type="email"
                        className="border-2 w-full h-10 p-2 rounded-sm focus:outline-none"
                        placeholder="Enter Email"
                        onChange={(event) => setEmail(event.target.value)}
                        value={email}
                      />
                    </div>
                  </div>
                  <div className={"grid grid-cols-2 gap-2"}>
                    <div className={"mt-4"}>
                      <label htmlFor="">
                        Mobile Number{" "}
                        <small className={"text-red-500"}>*</small>
                      </label>
                      <input
                        type="text"
                        className="border-2 w-full h-10 p-2 rounded-sm focus:outline-none"
                        placeholder="Enter Mobile Number"
                        onChange={(event) =>
                          setMobileNumber(event.target.value)
                        }
                        value={mobileNumber}
                        min={0}
                        required={true}
                      />
                    </div>
                    <div className={"mt-4"}>
                      <label htmlFor="">
                        State <small className={"text-red-500"}>*</small>
                      </label>
                      <input
                        type="text"
                        className="border-2 w-full h-10 p-2 rounded-sm focus:outline-none"
                        placeholder="Enter State"
                        onChange={(event) => setState(event.target.value)}
                        value={state}
                        min={0}
                        required={true}
                      />
                    </div>
                  </div>

                  <div className={"mt-4"}>
                    <label htmlFor="">
                      Address <small className={"text-red-500"}>*</small>
                    </label>
                    <textarea
                      className="border-2 w-full p-2 rounded-sm focus:outline-none"
                      placeholder="Enter Address"
                      onChange={(event) => setAddress(event.target.value)}
                      value={address}
                    />
                  </div>
                  <div className={"grid grid-cols-2 gap-2"}>
                    <div className={"mt-4"}>
                      <label htmlFor="">
                        City <small className={"text-red-500"}>*</small>
                      </label>
                      <input
                        className="border-2 w-full h-10 p-2 rounded-sm focus:outline-none"
                        placeholder="Enter City"
                        onChange={(event) => setCity(event.target.value)}
                        value={city}
                        required={true}
                      />
                    </div>
                    <div className={"mt-4"}>
                      <label htmlFor="">
                        Pincode <small className={"text-red-500"}>*</small>
                      </label>
                      <input
                        className="border-2 w-full h-10 p-2 rounded-sm focus:outline-none"
                        placeholder="Enter Pincode"
                        onChange={(event) => setPincode(event.target.value)}
                        value={pincode}
                        required={true}
                      />
                    </div>
                  </div>
                </div>
              ) : customerType === "already" ? (
                <div className={"grid grid-cols-1 gap-2"}>
                  {/*<div className={"mt-4"}>*/}
                  {/*  <label htmlFor="">Select Customer</label>*/}
                  {/*  <CreatableSelect*/}
                  {/*    isClearable*/}
                  {/*    options={userOptions}*/}
                  {/*    onChange={(value) => {*/}
                  {/*      setCustomers(value);*/}
                  {/*    }}*/}
                  {/*    required={true}*/}
                  {/*  />*/}
                  {/*</div>*/}
                  <div className={"mt-4"}>
                    <label htmlFor="">
                      Select Customer{" "}
                      <small className={"text-red-500"}>*</small>
                    </label>
                    <div className="input-container">
                      <input
                        type="text"
                        className="border-2 w-full h-10 p-2 rounded-sm focus:outline-none icon-input"
                        placeholder="Search By Name Or Username"
                        onChange={handleOnSearch}
                        value={search}
                        required={true}
                      />
                      <span className="input-left-icon">&#128269;</span>
                      {searchLoader ? (
                        <span className="input-right-icon">
                          <i className={"fa fa-spinner fa-spin"}></i>
                        </span>
                      ) : search.length > 0 ? (
                        <span
                          className="input-right-icon"
                          onClick={() => {
                            setSearch("");
                            setMembersData([]);
                            setCustomers("");
                          }}
                        >
                          <i className={"fa fa-close"}></i>
                        </span>
                      ) : null}
                    </div>
                    <ul>
                      {membersData.map((item) => (
                        <li
                          key={item.username}
                          className={"list-design"}
                          onClick={() => handleSelect(item)}
                        >
                          {`${item.name} (${item.username})`}
                        </li>
                      ))}
                    </ul>
                  </div>
                </div>
              ) : null}
              <div className={"grid grid-cols-2 gap-2"}>
                <div className={"mt-4"}>
                  <label htmlFor="">Received Amount</label>
                  <input
                    type="number"
                    className="border-2 w-full h-10 p-2 rounded-sm focus:outline-none"
                    placeholder="Enter Received Amount"
                    onChange={(event) => {
                      remainingBalance(event.target.value);
                    }}
                    value={receivedAmount}
                    required={true}
                  />
                </div>
                <div className={"mt-4"}>
                  <label htmlFor="">Paying Amount</label>
                  <input
                    type="text"
                    className="border-2 w-full h-10 p-2 rounded-sm focus:outline-none"
                    placeholder="Enter Paying Amount"
                    onChange={(event) => {
                      setPayingAmount(event.target.value);
                    }}
                    value={payingAmount}
                    required={true}
                  />
                </div>
              </div>
              <div className={"grid grid-cols-2 gap-2"}>
                <div className={"mt-4"}>
                  <label htmlFor="">Balance Amount</label>
                  <input
                    type="number"
                    className="border-2 w-full h-10 p-2 rounded-sm focus:outline-none bg-gray-100"
                    readOnly={true}
                    value={Math.abs(balance)}
                  />
                </div>
                <div className={"mt-4"}>
                  <label htmlFor="">
                    Select Payment
                    <small className={"text-red-500"}>*</small>
                  </label>
                  <CreatableSelect
                    isClearable
                    options={paymentOptions}
                    onChange={(value) => {
                      setPaymentType(value);
                    }}
                    required={true}
                  />
                </div>
              </div>
              <div className={"mt-4 mb-4"}>
                <label htmlFor="">Payment Note (Optional)</label>
                <textarea
                  className="border-2 w-full p-2 rounded-sm focus:outline-none"
                  placeholder="Enter Payment Note"
                  onChange={(event) => setPaymentNote(event.target.value)}
                  value={paymentNote}
                />
              </div>
              <div>
                <button
                  type={"submit"}
                  className="text-center w-full bg-green-500 text-white focus:outline-none hover:bg-green-600 p-2 disabled:opacity-25"
                  disabled={loader}
                >
                  PLACE ORDER
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </>
  );
};

export default AbhhyamProceedOrder;
