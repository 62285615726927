import "../App.css";
import "../style.css";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import useSound from "use-sound";
import addSound from "../sound/add-sound.mp3";
import useCategoryFetch from "../hooks/useCategoryFetch";
import useProductFetch from "../hooks/useProductFetch";
import { ACCOUNT_TYPE, BASE_URL, CART_DATA, IMAGE_URL } from "../constant";
import MobileHoldOrder from "../modals/MobileHoldOrder";
import { ErrorToast } from "../loader/Toast";
import errorSound from "../sound/error.wav";
import { confirmAlert } from "react-confirm-alert";
import BarcodeScannerComponent from "react-qr-barcode-scanner";
import ProceedOrder from "../modals/ProceedOrder";
import AbhhyamProceedOrder from "../modals/AbhhyamProceedOrder";

function MobileHome() {
  const navigate = useNavigate();

  const queryParams = new URLSearchParams(window.location.search);
  const canteenId = queryParams.get("canteenId");
  const barcode = queryParams.get("barcode");

  const [playAddSound] = useSound(addSound);
  const [playErrorSound] = useSound(errorSound);

  const scrollableDivRef = useRef(null);

  let items = 0;
  let pv = 0;
  let subTotal = 0;
  let grandTotal = 0;
  let tax = 0;
  let discount = 0;
  const [modal, setModal] = useState(false);

  const [data, setData] = useState([]); // Your data array or object
  const [searchQuery, setSearchQuery] = useState("");
  const [cartData, setCartData] = useState([]);
  const [page, setPage] = useState(0);
  const [selectCategory, setSelectCategory] = useState("all");
  const [accountType, setAccountType] = useState("canteen");
  const [holdOrderModal, setHoldOrderModal] = useState(false);
  const [paymentModal, setPaymentModal] = useState(false);
  const [itemCount, setItemCount] = useState(0);
  const [holdOrderId, setHoldOrderId] = useState(0);
  const [canteenDetails, setCanteenDetails] = useState("");
  const [members, setMembers] = useState([]);
  const [scanner, setScanner] = useState(false);
  const [searchBarCode, setSearchBarCode] = useState("");
  const [searchType, setSearchType] = useState("products");

  const [loading, categoryData] = useCategoryFetch(canteenId);

  const [productLoading, productData] = useProductFetch(
    page,
    selectCategory,
    searchType === "barcode" ? searchBarCode : searchQuery,
    canteenId,
    searchType
  );

  const handleSearch = (event) => {
    setSearchQuery(event.target.value);
  };

  useEffect(() => {
    setData(productData);
  }, [productData]);

  const addToCart = useCallback(
    (value, operator) => {
      const prevCartData = [...cartData];

      const findIndex = prevCartData.findIndex((d) => d.id === value.id);
      if (findIndex > -1) {
        if (operator === "increase") {
          // if (accountType === "canteen") {
          if (value.stock > prevCartData[findIndex].quantity) {
            prevCartData[findIndex].quantity =
              prevCartData[findIndex].quantity + 1;
            playAddSound();
          } else {
            playErrorSound();
            ErrorToast(`Stock not available`);
          }
          // } else {
          //   prevCartData[findIndex].quantity =
          //     prevCartData[findIndex].quantity + 1;
          //   playAddSound();
          // }
        } else if (operator === "decrease") {
          if (prevCartData[findIndex].quantity === 1) {
            prevCartData[findIndex].quantity =
              prevCartData[findIndex].quantity - 1;
            prevCartData.splice(findIndex, 1);
          } else {
            prevCartData[findIndex].quantity =
              prevCartData[findIndex].quantity - 1;
          }
        } else {
          prevCartData[findIndex].quantity = 0;
          prevCartData.splice(findIndex, 1);
        }
        setCartData(prevCartData);
      } else {
        // if (accountType === "canteen") {
        if (value.stock > 0) {
          value.quantity = 1;
          setCartData([...prevCartData, value]);
          playAddSound();
        } else {
          playErrorSound();
          ErrorToast(`Stock not available`);
        }
        // } else {
        //   playAddSound();
        //   value.quantity = 1;
        //   setCartData([...prevCartData, value]);
        // }
      }

      setItemCount((prevItemCount) => prevItemCount + 1);
    },
    [accountType, cartData, playAddSound, playErrorSound]
  );

  const getCanteenDetails = useCallback(async () => {
    let formData = new FormData();
    formData.append("canteen_id", canteenId);

    let requestOptions = {
      method: "POST",
      body: formData,
      redirect: "follow",
    };

    fetch(`${BASE_URL}get_canteen_wallet`, requestOptions)
      .then((response) => response.json())
      .then((result) => setCanteenDetails(result.data))
      .catch((error) => console.log("error", error));
  }, [canteenId]);

  const getMembers = useCallback(async () => {
    let requestOptions = {
      method: "POST",
    };

    const response = await fetch(
      `${BASE_URL}get_abhhyam_member`,
      requestOptions
    );
    const newData = await response.json();
    setMembers(newData.data);
  }, []);

  useEffect(() => {
    if (barcode) {
      getBarcodeProduct(barcode);
    }
  }, [barcode]);

  useEffect(() => {
    getCanteenDetails();
    getMembers();
  }, [getCanteenDetails, getMembers]);

  const handleScroll = () => {
    const { scrollTop, clientHeight, scrollHeight } = scrollableDivRef.current;
    const bottomReached = scrollTop + clientHeight >= scrollHeight - 200;

    if (bottomReached) {
      // You can trigger an alert here or perform any other action you want
      setPage(page + 20);
    }
  };

  const getBarcodeProduct = async (barcode) => {
    let formData = new FormData();
    formData.append("canteen_id", canteenId);
    formData.append("barcode", barcode);

    let requestOptions = {
      method: "POST",
      body: formData,
    };

    const response = await fetch(
      `${BASE_URL}get_inventory_by_barcode`,
      requestOptions
    );
    const newData = await response.json();
    await addToCart(newData.data, "increase");
    setScanner(false);
  };

  useEffect(() => {
    const storeLocalData = localStorage.getItem(CART_DATA);
    let item = 0;
    if (storeLocalData) {
      const parseData = JSON.parse(storeLocalData);
      const cartStoreData = parseData.value;
      setCartData(cartStoreData);
      setHoldOrderId(parseData.orderId);
      // cartStoreData.map((cData) => {
      //   item += cData.quantity;
      //   return data.map((d, index) => {
      //     if (d.id === cData.id) {
      //       return (d[index].quantity = cData.quantity);
      //     }
      //   });
      // });
      setItemCount(item);
    }
  }, [data]);

  const handleSearchBarcode = (event) => {
    setSearchBarCode(event.target.value);
    setSearchType("barcode");
  };

  const singleCartRemove = (val) => {
    addToCart(val, "delete");
    const storeLocalData = localStorage.getItem(CART_DATA);
    if (storeLocalData) {
      const parseData = JSON.parse(storeLocalData);
      const arrayData = parseData.value;
      const findIndex = arrayData.findIndex((d) => d.id === val.id);
      if (findIndex > -1) {
        arrayData.splice(findIndex, 1);
        localStorage.setItem(
          CART_DATA,
          JSON.stringify({
            orderId: parseData.orderId,
            value: arrayData,
          })
        );
      }
    }
  };

  const taxCalculate = (price, tax) => {
    return (price * tax) / 100;
  };

  useEffect(() => {
    const type = localStorage.getItem(ACCOUNT_TYPE);
    if (type) {
      setAccountType(type);
    } else {
      localStorage.setItem(ACCOUNT_TYPE, "canteen");
      setAccountType("canteen");
    }
  }, [setAccountType]);

  if (scanner) {
    return (
      <div>
        <div
          className="absolute right-3 px-2 py-1 top-1 z-40"
          onClick={() => setScanner(false)}
        >
          <i className={"fa fa-circle-xmark fa-2x text-white"}></i>
        </div>
        <BarcodeScannerComponent
          onUpdate={(err, result) => {
            if (result) {
              getBarcodeProduct(result.text);
              setScanner(false);
            }
          }}
        />
      </div>
    );
  }

  return (
    <>
      <div className="flex flex-row h-screen antialiased text-blue-gray-800 overflow-hidden">
        <div className="flex-grow flex">
          <div className="flex flex-col bg-blue-gray-50 w-full">
            <div className={"grid grid-cols-2"}>
              <div
                onClick={() => {
                  confirmAlert({
                    message:
                      "Are you sure to do this. If you have added data to the cart then put it on hold because if the type is changed then the cart data will be deleted.",
                    buttons: [
                      {
                        label: "Yes",
                        onClick: () => {
                          localStorage.removeItem(CART_DATA);
                          localStorage.setItem(ACCOUNT_TYPE, "abhhyam");
                          setAccountType("abhhyam");
                          setCartData([]);
                          window.location.reload();
                        },
                      },
                      {
                        label: "No",
                      },
                    ],
                  });
                }}
                className={`${
                  accountType === "abhhyam" ? "bg-green-500" : "bg-gray-300"
                } text-center p-2 self-center text-white cursor-pointer`}
              >
                Abhhyam
              </div>

              <div
                onClick={() => {
                  confirmAlert({
                    message:
                      "Are you sure to do this. If you have added data to the cart then put it on hold because if the type is changed then the cart data will be deleted.",
                    buttons: [
                      {
                        label: "Yes",
                        onClick: () => {
                          localStorage.removeItem(CART_DATA);
                          localStorage.setItem(ACCOUNT_TYPE, "canteen");
                          setAccountType("canteen");
                          setCartData([]);
                          window.location.reload();
                        },
                      },
                      {
                        label: "No",
                      },
                    ],
                  });
                }}
                className={`${
                  accountType === "canteen" ? "bg-green-500" : "bg-gray-300"
                } text-center p-2 self-center text-white cursor-pointer`}
              >
                Canteen
              </div>
            </div>
            <div className="grid grid-cols-2">
              <Link
                to={"/hold-orders/" + canteenId}
                className="text-white text-center bg-orange-600 text-xs focus:outline-none p-1"
              >
                <i className={"fa fa-list"}></i> <br />
                Hold Orders
              </Link>
              <Link
                to={
                  accountType === "abhhyam"
                    ? "/abhhyamTransactions/" + canteenId
                    : "/transactions/" + canteenId
                }
                className="text-white text-center bg-sky-600 text-xs focus:outline-none p-1"
              >
                <i className={"fa fa-rupee"}></i> <br />
                Transactions
              </Link>
            </div>
            <div className="grid grid-cols-5 mt-2">
              <div className="flex px-2 flex-row relative justify-between col-span-6 mb-2">
                <div className="absolute left-3 px-2 py-1 top-1 rounded-full bg-orange-500 text-white">
                  <i className={"fa fa-search"}></i>
                </div>
                <input
                  type="text"
                  className="bg-white rounded-full shadow full w-full h-10 py-4 pl-10 transition-shadow focus:shadow-2xl focus:outline-none"
                  style={{ fontSize: 9 }}
                  placeholder="Search products by name and product code"
                  onChange={handleSearch}
                  value={searchQuery}
                />
                {searchQuery ? (
                  <div
                    className="absolute right-3 px-2 py-1 top-1"
                    onClick={() => setSearchQuery("")}
                  >
                    <i className={"fa fa-circle-xmark"}></i>
                  </div>
                ) : null}
              </div>
              <div className="flex px-2 flex-row relative justify-end mb-2 col-span-4">
                <div className="absolute left-3 px-2 py-1 top-1 rounded-full bg-orange-500 text-white">
                  <i className={"fa fa-search"}></i>
                </div>
                <input
                  type="text"
                  style={{ fontSize: 9 }}
                  className="bg-white rounded-full shadow text-sm full w-full h-10 py-4 pl-10 transition-shadow focus:shadow-2xl focus:outline-none"
                  placeholder="Search by bar code"
                  onChange={handleSearchBarcode}
                  value={searchBarCode}
                />
                {searchBarCode ? (
                  <div
                    className="absolute right-3 px-2 py-1 top-1"
                    onClick={() => setSearchBarCode("")}
                  >
                    <i className={"fa fa-circle-xmark"}></i>
                  </div>
                ) : null}
              </div>

              <div
                className="flex px-2 flex-row relative justify-between"
                onClick={() => navigate("/scanner/" + canteenId)}
              >
                <img
                  src="https://cdn-icons-png.flaticon.com/512/5393/5393325.png"
                  alt=""
                  className={"barcode-size"}
                />
              </div>
            </div>

            <div className="grid grid-cols-3 gap-1 justify-space-between text-center m-2 rounded-lg text-xs overflow-x">
              <div
                onClick={() => {
                  setSelectCategory("all");
                  setPage(0);
                }}
                className={`${
                  selectCategory === "all" ? "bg-orange-500" : "bg-gray-300"
                } mx-2 rounded-lg text-center p-2 self-center text-white cursor-pointer`}
              >
                All Category
              </div>
              {categoryData.map((cate, index) => {
                return (
                  <div
                    onClick={() => {
                      setSelectCategory(cate.id);
                      setPage(0);
                    }}
                    key={index}
                    className={`${
                      selectCategory === cate.id
                        ? "bg-orange-500"
                        : "bg-gray-300"
                    } mx-2 rounded-lg text-center p-2 self-center text-white cursor-pointer`}
                  >
                    {cate.name}
                  </div>
                );
              })}
            </div>

            <div
              className="h-full overflow-y-auto px-2"
              ref={scrollableDivRef}
              onScroll={handleScroll}
            >
              <div className="grid grid-cols-2 mb-10">
                {data.map((val, index) => {
                  return (
                    <div
                      className="flex flex-col justify-between transition-shadow overflow-hidden border-2 bg-white shadow hover:shadow-lg"
                      key={index}
                    >
                      <div className="flex pb-3 justify-between items-center">
                        <p
                          className={
                            "line-through text-red-600 font-semibold text-xs"
                          }
                        >
                          Rs. {val.sell_price}
                        </p>
                        <p className={"text-green-600 font-semibold text-xs"}>
                          Rs.{" "}
                          {accountType === "abhhyam" ? val.dp_price : val.csp}
                        </p>
                        {accountType === "abhhyam" ? (
                          <p className="font-semibold text-xs text-white bg-orange-600 px-1">
                            PV : {val.pv}
                          </p>
                        ) : null}
                      </div>
                      <center>
                        <img
                          src={IMAGE_URL + val.image}
                          alt={val.product_name}
                          className={"img-size"}
                          onError={({ currentTarget }) => {
                            currentTarget.onerror = null; // prevents looping
                            currentTarget.src =
                              "https://ardhsainikcanteen.in/public/assets/website/images/asc_logo.jpg";
                          }}
                        />
                        {/* {accountType === "canteen" ? ( */}
                        <p
                          className={`mx-2 text-xs font-bold ${
                            val.stock > 0 ? "text-green-700" : "text-red-600"
                          }`}
                        >
                          Stock : {val.stock}
                        </p>
                        {/* ) : null} */}
                        <p className={"mx-2 text-xs font-semibold"}>
                          {val.product_name}
                        </p>
                        <p
                          className={
                            "mx-2 mb-0 text-xs font-semibold text-green-500"
                          }
                        >
                          {val.product_code}
                        </p>
                        <p
                          className={
                            "mx-2 mb-0 text-xs font-semibold text-red-500"
                          }
                        >
                          <i className={"fa fa-barcode"}></i> {val.barcode}
                        </p>
                      </center>
                      {val.quantity > 0 ? (
                        <div className="grid grid-cols-3">
                          <button
                            className="text-center text-white bg-green-600 hover:bg-green-700 focus:outline-none"
                            onClick={() => addToCart(val, "decrease")}
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              className="h-6 w-3 inline-block"
                              fill="none"
                              viewBox="0 0 24 24"
                              stroke="currentColor"
                            >
                              <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth="2"
                                d="M20 12H4"
                              />
                            </svg>
                          </button>
                          <input
                            type="text"
                            className="bg-white text-center shadow focus:outline-none focus:shadow-lg"
                            value={val.quantity}
                            readOnly
                          />
                          <button
                            className="text-center text-white bg-green-600 hover:bg-green-700 focus:outline-none"
                            onClick={() => addToCart(val, "increase")}
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              className="h-6 w-3 inline-block"
                              fill="none"
                              viewBox="0 0 24 24"
                              stroke="currentColor"
                            >
                              <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth="2"
                                d="M12 6v6m0 0v6m0-6h6m-6 0H6"
                              />
                            </svg>
                          </button>
                        </div>
                      ) : (
                        <div className="grid grid-cols-1">
                          <button
                            className="text-center text-sm text-white bg-green-600 hover:bg-green-700 focus:outline-none p-1"
                            onClick={() => addToCart(val, "increase")}
                          >
                            ADD TO CART
                          </button>
                        </div>
                      )}
                    </div>
                  );
                })}
              </div>

              {data.length === 0 ? (
                <div className="select-none bg-blue-gray-100 rounded-3xl flex-wrap content-center justify-center">
                  <div className="w-full text-center">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className="h-24 w-24 inline-block"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="2"
                        d="M4 7v10c0 2.21 3.582 4 8 4s8-1.79 8-4V7M4 7c0 2.21 3.582 4 8 4s8-1.79 8-4M4 7c0-2.21 3.582-4 8-4s8 1.79 8 4m0 5c0 2.21-3.582 4-8 4s-8-1.79-8-4"
                      />
                    </svg>
                    <p className="text-xl">
                      YOU DON'T HAVE
                      <br />
                      ANY PRODUCTS TO SHOW
                    </p>
                  </div>
                </div>
              ) : null}
            </div>
            <div
              className="fixed glass w-full h-full"
              style={{ display: modal ? "flex" : "none" }}
            >
              <div className="bg-white shadow-xl w-full h-full">
                <div className="text-center border-b-2 p-2 flex justify-between">
                  <h3 className="text-center text-sm font-bold">Checkout</h3>
                  <i
                    className={"fa fa-close"}
                    onClick={() => setModal(false)}
                  ></i>
                </div>

                <div className="overflow-y-auto h-96">
                  {cartData.map((val, index) => {
                    if (val.quantity !== undefined) {
                      items += val.quantity;
                      pv += val.pv * val.quantity;
                      subTotal += val.sell_price * val.quantity;
                      grandTotal +=
                        accountType === "abhhyam"
                          ? val.dp_price * val.quantity
                          : val.csp * val.quantity;
                      tax +=
                        taxCalculate(
                          accountType === "abhhyam" ? val.dp_price : val.csp,
                          val.tax_rate
                        ) * val.quantity;
                      discount +=
                        accountType === "abhhyam"
                          ? val.discount * val.quantity
                          : val.canteen_discount * val.quantity;
                    }
                    return (
                      <div className="bg-gray-100 p-1 border-y-2" key={index}>
                        <p className={"text-xs"}>{val.product_name}</p>

                        <div className="flex justify-between">
                          <p
                            className={
                              "line-through text-red-600 font-semibold text-xs"
                            }
                          >
                            Rs. {val.sell_price}
                          </p>
                          &nbsp; &nbsp;
                          <p className={"text-green-600 font-semibold text-xs"}>
                            Rs.{" "}
                            {accountType === "abhhyam" ? val.dp_price : val.csp}
                          </p>
                          &nbsp; &nbsp;
                          {accountType === "abhhyam" ? (
                            <p className="nowrap font-semibold text-center text-xs">
                              PV : {val.pv}
                            </p>
                          ) : null}
                        </div>

                        <div className={"flex justify-between"}>
                          <div className={"flex justify-between"}>
                            <div className="w-20 grid grid-cols-3">
                              <button
                                className="text-center text-white bg-green-600 hover:bg-green-700 focus:outline-none"
                                onClick={() => addToCart(val, "decrease")}
                              >
                                <i className={"fa fa-minus mt-1"}></i>
                              </button>
                              <input
                                type="text"
                                className="bg-white text-center shadow focus:outline-none focus:shadow-lg text-sm"
                                value={val.quantity}
                              />
                              <button
                                className="text-center text-white bg-green-600 hover:bg-green-700 focus:outline-none"
                                onClick={() => addToCart(val, "increase")}
                              >
                                <i className={"fa fa-plus mt-1"}></i>
                              </button>
                            </div>
                          </div>
                          <button
                            className="text-blue-gray-300 hover:text-pink-500 focus:outline-none align-top"
                            onClick={() => singleCartRemove(val)}
                          >
                            <i className={"fa fa-trash-alt text-red-500"}></i>
                          </button>
                        </div>
                      </div>
                    );
                  })}
                </div>

                <div className="justify-space-between fixed w-full bottom-0">
                  <div className="select-none h-auto w-full text-center pt-3 pb-4 px-4 border-t-2">
                    <div className="flex mb-3 text-xs font-semibold text-blue-gray-700 justify-between">
                      <div>Total Items</div>
                      <div className="text-right">{items}</div>
                    </div>
                    <div className="flex mb-3 text-xs font-semibold text-blue-gray-700 justify-between">
                      <div>Total PV</div>
                      <div className="text-right">{pv.toFixed(2)}</div>
                    </div>
                    <div className="flex mb-3 text-xs font-semibold text-blue-gray-700 justify-between">
                      <div>Sub Total</div>
                      <div className="text-right">
                        Rs. {subTotal.toFixed(2)}
                      </div>
                    </div>
                    <div className="flex mb-3 text-xs font-semibold text-blue-gray-700 justify-between">
                      <div>Discount</div>
                      <div className="text-right text-red-600">
                        - Rs. {discount.toFixed(2)}
                      </div>
                    </div>
                    {/*<div className="flex mb-3 text-xs font-semibold text-blue-gray-700 justify-between">*/}
                    {/*  <div>Tax</div>*/}
                    {/*  <div className="text-right text-green-600">*/}
                    {/*    + Rs. {tax.toFixed(2)}*/}
                    {/*  </div>*/}
                    {/*</div>*/}
                    <div className="flex text-xs font-semibold text-blue-gray-700 justify-between">
                      <div>Payable Amount</div>
                      <div className="text-right">
                        Rs. {grandTotal.toFixed(2)}
                      </div>
                    </div>
                  </div>
                  <div className="flex">
                    <button
                      className="text-center w-full bg-orange-500 text-white focus:outline-none hover:bg-green-600 p-1"
                      onClick={() => setHoldOrderModal(true)}
                    >
                      Hold Order
                    </button>
                    <button
                      className="text-center w-full bg-green-500 text-white focus:outline-none hover:bg-green-600 p-1"
                      onClick={() => setPaymentModal(true)}
                    >
                      Proceed
                    </button>
                  </div>
                </div>
              </div>
            </div>
            {cartData.length > 0 ? (
              !modal ? (
                <div className="grid grid-cols-2 justify-space-between text-center bg-white text-xs items-center fixed w-full bottom-0">
                  <p>{items} Items</p>
                  <button
                    className="text-center text-white bg-orange-600 hover:bg-orange-700 focus:outline-none p-1"
                    onClick={() => setModal(true)}
                  >
                    Rs. {grandTotal.toFixed(2)} <br />
                    Proceed
                  </button>
                </div>
              ) : null
            ) : null}
          </div>
        </div>
      </div>

      <MobileHoldOrder
        holdOrderModal={holdOrderModal}
        setHoldOrderModal={setHoldOrderModal}
        cartData={cartData}
        items={items}
        pv={pv}
        subTotal={subTotal}
        discount={discount}
        tax={tax}
        grandTotal={grandTotal}
        accountType={accountType}
        setCartData={setCartData}
        canteenId={canteenId}
        setModal={setModal}
        holdOrderId={holdOrderId}
      />

      {accountType === "abhhyam" ? (
        <AbhhyamProceedOrder
          paymentModal={paymentModal}
          setPaymentModal={setPaymentModal}
          cartData={cartData}
          tax={tax}
          grandTotal={grandTotal}
          accountType={accountType}
          setCartData={setCartData}
          canteenId={canteenId}
          members={members}
          canteenDetails={canteenDetails}
          holdOrderId={holdOrderId}
        />
      ) : (
        <ProceedOrder
          paymentModal={paymentModal}
          setPaymentModal={setPaymentModal}
          cartData={cartData}
          tax={tax}
          grandTotal={grandTotal}
          accountType={accountType}
          setCartData={setCartData}
          canteenId={canteenId}
          holdOrderId={holdOrderId}
        />
      )}
    </>
  );
}

export default MobileHome;
