import "../App.css";
import "../style.css";
import React, { useCallback, useEffect, useState } from "react";
import addSound from "../sound/add-sound.mp3";
import useSound from "use-sound";
import { Link } from "react-router-dom";
import HoldOrder from "../modals/HoldOrder";
import ProceedOrder from "../modals/ProceedOrder";
import { ACCOUNT_TYPE, CART_DATA } from "../constant";
import { ErrorToast } from "../loader/Toast";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";
import Marquee from "react-fast-marquee";
import errorSound from "../sound/error.wav";
import AbhhyamProceedOrder from "../modals/AbhhyamProceedOrder";

function TabCartComponent({
  cartData,
  setCartData,
  itemCount,
  setItemCount,
  setAccountType,
  accountType,
  canteenId,
  holdOrderId,
  canteenDetails,
  members,
}) {
  const [playAddSound] = useSound(addSound);
  const [playErrorSound] = useSound(errorSound);

  let items = 0;
  let pv = 0;
  let subTotal = 0;
  let grandTotal = 0;
  let tax = 0;
  let discount = 0;

  const [holdOrderModal, setHoldOrderModal] = useState(false);
  const [paymentModal, setPaymentModal] = useState(false);

  const addToCart = useCallback(
    (value, operator) => {
      const prevCartData = [...cartData];

      const findIndex = prevCartData.findIndex((d) => d.id === value.id);
      if (findIndex > -1) {
        if (operator === "increase") {
          // if (accountType === "canteen") {
          if (value.stock > prevCartData[findIndex].quantity) {
            prevCartData[findIndex].quantity =
              prevCartData[findIndex].quantity + 1;
            playAddSound();
          } else {
            playErrorSound();
            ErrorToast(`Stock not available`);
          }
          // } else {
          //   prevCartData[findIndex].quantity =
          //     prevCartData[findIndex].quantity + 1;
          //   playAddSound();
          // }
          setItemCount((prevItemCount) => prevItemCount + 1);
        } else if (operator === "decrease") {
          if (prevCartData[findIndex].quantity === 1) {
            prevCartData.splice(findIndex, 1);
          } else {
            prevCartData[findIndex].quantity =
              prevCartData[findIndex].quantity - 1;
          }
          setItemCount((prevItemCount) => prevItemCount - 1);
        } else {
          setItemCount(
            (prevItemCount) => prevItemCount - prevCartData[findIndex].quantity
          );
          prevCartData.splice(findIndex, 1);
        }
        setCartData(prevCartData);
      } else {
        // if (accountType === "canteen") {
        if (value.stock > 0) {
          value.quantity = 1;
          setCartData([...prevCartData, value]);
          playAddSound();
        } else {
          playErrorSound();
          ErrorToast(`Stock not available`);
        }
        setItemCount(1);
        // } else {
        //   playAddSound();
        //   value.quantity = 1;
        //   setCartData([...prevCartData, value]);
        //   setItemCount(1);
        // }
      }
    },
    [
      accountType,
      cartData,
      playAddSound,
      playErrorSound,
      setCartData,
      setItemCount,
    ]
  );

  const clearCart = () => {
    setCartData([]);
    setItemCount(0);
    localStorage.removeItem(CART_DATA);
  };

  const singleCartRemove = (val) => {
    addToCart(val, "delete");
    const storeLocalData = localStorage.getItem(CART_DATA);
    if (storeLocalData) {
      const parseData = JSON.parse(storeLocalData);
      const arrayData = parseData.value;
      const findIndex = arrayData.findIndex((d) => d.id === val.id);
      if (findIndex > -1) {
        arrayData.splice(findIndex, 1);
        localStorage.setItem(
          CART_DATA,
          JSON.stringify({
            orderId: parseData.orderId,
            value: arrayData,
          })
        );
      }
    }
  };

  const taxCalculate = (price, tax) => {
    return (price * tax) / 100;
  };

  useEffect(() => {
    const type = localStorage.getItem(ACCOUNT_TYPE);
    if (type) {
      setAccountType(type);
    } else {
      localStorage.setItem(ACCOUNT_TYPE, "canteen");
      setAccountType("canteen");
    }
  }, [setAccountType]);

  return (
    <>
      <div className="w-5/12 flex flex-col bg-blue-gray-50 bg-white pr-4 pl-2 py-4">
        <Marquee>
          <div className={"text-red-400 font-bold"}>
            {`YOUR BILLING POS TYPE ${accountType.toUpperCase()} IS ACTIVE.`}
            &nbsp;
          </div>
        </Marquee>
        {canteenDetails.v_id > 0 ? (
          <div className={"grid grid-cols-2 mt-2"}>
            <div
              onClick={() => {
                confirmAlert({
                  message:
                    "Are you sure to do this. If you have added data to the cart then put it on hold because if the type is changed then the cart data will be deleted.",
                  buttons: [
                    {
                      label: "Yes",
                      onClick: () => {
                        localStorage.removeItem(CART_DATA);
                        localStorage.setItem(ACCOUNT_TYPE, "abhhyam");
                        setAccountType("abhhyam");
                        setCartData([]);
                      },
                    },
                    {
                      label: "No",
                    },
                  ],
                });
              }}
              className={`${
                accountType === "abhhyam" ? "bg-green-500" : "bg-gray-300"
              } text-center p-2 self-center text-white cursor-pointer`}
            >
              ABHHYAM
            </div>

            <div
              onClick={() => {
                confirmAlert({
                  message:
                    "Are you sure to do this. If you have added data to the cart then put it on hold because if the type is changed then the cart data will be deleted.",
                  buttons: [
                    {
                      label: "Yes",
                      onClick: () => {
                        localStorage.removeItem(CART_DATA);
                        localStorage.setItem(ACCOUNT_TYPE, "canteen");
                        setAccountType("canteen");
                        setCartData([]);
                      },
                    },
                    {
                      label: "No",
                    },
                  ],
                });
              }}
              className={`${
                accountType === "canteen" ? "bg-green-500" : "bg-gray-300"
              } text-center p-2 self-center text-white cursor-pointer`}
            >
              CANTEEN
            </div>
          </div>
        ) : null}

        <div className="grid grid-cols-2 mt-2">
          <Link
            to={"/hold-orders/" + canteenId}
            className="text-white text-center bg-orange-600 text-xs focus:outline-none p-1"
          >
            <i className={"fa fa-list"}></i> <br />
            Hold Orders
          </Link>
          <Link
            to={
              accountType === "abhhyam"
                ? "/abhhyamTransactions/" + canteenId
                : "/transactions/" + canteenId
            }
            className="text-white text-center bg-sky-600 text-xs focus:outline-none p-1"
          >
            <i className={"fa fa-rupee"}></i> <br />
            Transactions
          </Link>
        </div>
        <div className="bg-white flex flex-col h-full shadow">
          {cartData.length > 0 ? (
            <div className="flex-1 flex flex-col overflow-y-auto">
              <div className="flex justify-between p-2">
                <div className="text-lg relative">
                  <i className={"fa fa-shopping-cart"}></i>
                  <div className="text-center absolute bg-orange-500 text-white w-5 h-5 text-xs p-0 leading-5 rounded-full -right-3 top-3">
                    {itemCount ? itemCount : 1}
                  </div>
                </div>
                <button
                  className="text-white bg-red-500 text-xs focus:outline-none p-2"
                  onClick={clearCart}
                >
                  <i className={"fa fa-trash-alt"}></i> Clear All
                </button>
              </div>

              <div className="flex-1 w-full mt-2 overflow-y-auto">
                {cartData.map((val, index) => {
                  items += val.quantity;
                  pv += val.pv * val.quantity;

                  subTotal += val.sell_price * val.quantity;

                  grandTotal +=
                    accountType === "abhhyam"
                      ? val.dp_price * val.quantity
                      : val.csp * val.quantity;
                  tax +=
                    taxCalculate(
                      accountType === "abhhyam" ? val.dp_price : val.csp,
                      val.tax_rate
                    ) * val.quantity;
                  discount +=
                    accountType === "abhhyam"
                      ? val.discount * val.quantity
                      : val.canteen_discount * val.quantity;

                  return (
                    <div
                      className="select-none bg-blue-gray-50 w-full text-blue-gray-700 py-2 px-2 border-b-2"
                      key={index}
                    >
                      <p className={"text-xs"}>{val.product_name}</p>
                      <div className="flex justify-between">
                        <p
                          className={
                            "line-through text-red-600 font-bold text-xs italic"
                          }
                        >
                          Rs. {val.sell_price}
                        </p>

                        <p className={"text-green-600 font-bold text-xs"}>
                          Rs.{" "}
                          {accountType === "abhhyam" ? val.dp_price : val.csp}
                        </p>

                        {accountType === "abhhyam" ? (
                          <p className="nowrap font-bold text-center text-xs">
                            PV : {val.pv}
                          </p>
                        ) : null}
                      </div>

                      <div className={"flex justify-between"}>
                        <div className="w-20 grid grid-cols-3">
                          <button
                            className="text-center text-white bg-blue-gray-600 hover:bg-blue-gray-700 focus:outline-none"
                            onClick={() => addToCart(val, "decrease")}
                          >
                            <i className={"fa fa-minus mt-1"}></i>
                          </button>
                          <input
                            type="text"
                            className="bg-white text-center shadow focus:outline-none focus:shadow-lg text-sm"
                            value={val.quantity}
                          />
                          <button
                            className="text-center text-white bg-blue-gray-600 hover:bg-blue-gray-700 focus:outline-none"
                            onClick={() => addToCart(val, "increase")}
                          >
                            <i className={"fa fa-plus mt-1"}></i>
                          </button>
                        </div>
                        <button
                          className="text-blue-gray-300 hover:text-pink-500 focus:outline-none align-top"
                          onClick={() => singleCartRemove(val)}
                        >
                          <i className={"fa fa-trash-alt text-red-500"}></i>
                        </button>
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          ) : (
            <div className="flex-1 w-full p-4 opacity-25 select-none flex flex-col flex-wrap content-center justify-center">
              <i className={"fa fa-shopping-cart fa-5x"}></i>
              <br />
              <p>CART EMPTY</p>
            </div>
          )}

          <div className="select-none h-auto w-full text-center pt-3 pb-4 px-4 border-t-2">
            <div className="flex mb-3 text-sm font-bold text-blue-gray-700 justify-between">
              <div>Total Items</div>
              <div className="">{items}</div>
            </div>
            {accountType === "abhhyam" ? (
              <div className="flex mb-3 text-sm font-bold text-blue-gray-700 justify-between">
                <div>Total PV</div>
                <div className="">{pv.toFixed(2)}</div>
              </div>
            ) : null}
            <div className="flex mb-3 text-sm font-bold text-blue-gray-700 justify-between">
              <div>Sub Total</div>
              <div className="">Rs. {subTotal.toFixed(2)}</div>
            </div>
            <div className="flex mb-3 text-sm font-bold text-blue-gray-700 justify-between">
              <div>Discount</div>
              <div className="text-red-600">- Rs. {discount.toFixed(2)}</div>
            </div>
            {/*<div className="flex mb-3 text-sm font-bold text-blue-gray-700 justify-between">*/}
            {/*  <div>Tax</div>*/}
            {/*  <div className="text-green-600">+ Rs. {tax.toFixed(2)}</div>*/}
            {/*</div>*/}
            <div className="flex mb-3 text-sm font-bold text-blue-gray-700 justify-between">
              <div>
                Payable <br /> Amount
              </div>
              <div>Rs. {grandTotal.toFixed(2)}</div>
            </div>
          </div>
          {cartData.length > 0 ? (
            <div className={"grid grid-cols-2"}>
              <button
                className="text-white bg-orange-400 text-sm w-full py-2 focus:outline-none disabled:opacity-25"
                onClick={() => setHoldOrderModal(true)}
              >
                <i className={"fa fa-pause-circle"}></i>
                <br />
                HOLD ORDER
              </button>
              <button
                className="text-white bg-green-600 text-sm w-full py-2 focus:outline-none disabled:opacity-25"
                onClick={() => setPaymentModal(true)}
              >
                <i className={"fa fa-arrow-circle-right"}></i>
                <br />
                PROCEED
              </button>
            </div>
          ) : null}
        </div>
      </div>

      <HoldOrder
        holdOrderModal={holdOrderModal}
        setHoldOrderModal={setHoldOrderModal}
        cartData={cartData}
        items={items}
        pv={pv}
        subTotal={subTotal}
        discount={discount}
        tax={tax}
        grandTotal={grandTotal}
        accountType={accountType}
        setCartData={setCartData}
        canteenId={canteenId}
        holdOrderId={holdOrderId}
      />

      {accountType === "abhhyam" ? (
        <AbhhyamProceedOrder
          paymentModal={paymentModal}
          setPaymentModal={setPaymentModal}
          cartData={cartData}
          tax={tax}
          grandTotal={grandTotal}
          accountType={accountType}
          setCartData={setCartData}
          canteenId={canteenId}
          members={members}
          canteenDetails={canteenDetails}
          holdOrderId={holdOrderId}
        />
      ) : (
        <ProceedOrder
          paymentModal={paymentModal}
          setPaymentModal={setPaymentModal}
          cartData={cartData}
          tax={tax}
          grandTotal={grandTotal}
          accountType={accountType}
          setCartData={setCartData}
          canteenId={canteenId}
          holdOrderId={holdOrderId}
        />
      )}
    </>
  );
}

export default TabCartComponent;
