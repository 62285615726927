import React, { useEffect, useState } from "react";
import { BASE_URL, CART_DATA } from "../constant";
import toast from "react-hot-toast";
import { LoaderToast, SuccessToast } from "../loader/Toast";
import CreatableSelect from "react-select/creatable";
import { INVOICE_DATA } from "../constant";
import { useNavigate } from "react-router-dom";

const ProceedOrder = ({
  paymentModal,
  setPaymentModal,
  cartData,
  tax,
  grandTotal,
  accountType,
  setCartData,
  canteenId,
  holdOrderId,
}) => {
  const navigate = useNavigate();

  const [mobileNumber, setMobileNumber] = useState("");
  const [receivedAmount, setReceivedAmount] = useState("");
  const [payingAmount, setPayingAmount] = useState("");
  const [paymentType, setPaymentType] = useState("");
  const [paymentNote, setPaymentNote] = useState("");
  const [balance, setBalance] = useState(0.0);

  const [loader, setLoader] = useState(false);

  const submitProceedOrder = async (e) => {
    e.preventDefault();
    try {
      setLoader(true);
      LoaderToast("Order Processing");
      var myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");

      let bodyData = [];
      cartData.map((cart) => {
        bodyData.push({
          product_id: cart.product_id,
          id: cart.product_id,
          qty: cart.quantity,
          discount: cart.canteen_discount,
          price_sell: cart.sell_price,
          canteen_csp: cart.csp,
          tax_rate: cart.tax_rate,
          canteen_id: canteenId,
          inv_id: cart.inv_id,
          local_status: 0,
        });
      });

      let raw = JSON.stringify({
        products_info: bodyData,
        total_amount: grandTotal.toFixed(2),
        canteen_id: canteenId,
        inventoryIds: mobileNumber,
        payment_note: paymentNote,
        paid_by: paymentType.value,
        customerNumber: mobileNumber,
        holdOrderId: holdOrderId,
      });

      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
      };

      const response = await fetch(
        `${BASE_URL}customer_invoice_generate`,
        requestOptions
      );
      const result = await response.json();

      if (result.status) {
        setCartData([]);
        setMobileNumber("");
        setReceivedAmount("");
        setPayingAmount("");
        setPaymentType("");
        setPaymentNote("");
        setLoader(false);
        setPaymentModal(false);
        localStorage.removeItem(CART_DATA);
        toast.dismiss();
        SuccessToast("Order created successfully.");
        const invoiceData = {
          invoiceId: result.invoiceId,
          invoiceData: result.invoice_data,
        };
        localStorage.removeItem(INVOICE_DATA);
        localStorage.setItem(INVOICE_DATA, JSON.stringify(invoiceData));

        navigate(
          `/canteenInvoice/${canteenId}/${mobileNumber}/${result.invoiceId}`
        );
      }
    } catch (e) {
      setLoader(false);
    }
  };

  const paymentOptions = [
    { value: "cash", label: "Cash" },
    { value: "upi", label: "UPI" },
    { value: "netbanking", label: "Net Banking" },
    { value: "debit/credit", label: "Debit/Credit" },
  ];

  useEffect(() => {
    setReceivedAmount(grandTotal.toFixed(2));
    setPayingAmount(grandTotal.toFixed(2));
  }, [grandTotal]);

  const remainingBalance = (value) => {
    setReceivedAmount(value);
    const amt = value - payingAmount;
    setBalance(amt.toFixed(2));
  };

  return (
    <>
      <div
        className="fixed glass w-full h-screen left-0 top-0 z-10 flex flex-wrap justify-center content-center"
        style={{ display: paymentModal ? "flex" : "none" }}
      >
        <div className="w-192 bg-white shadow-xl">
          <div className="text-center border-b-2 p-2 flex justify-between align-middle">
            <h3 className="text-center text-xl">Payment Summary</h3>
            <i
              className={"fa fa-close fa-2x"}
              onClick={() => setPaymentModal(false)}
            ></i>
          </div>

          <form onSubmit={submitProceedOrder}>
            <div className={"p-4"}>
              <div className={"grid grid-cols-1"}>
                <div className={"mt-4"}>
                  <label htmlFor="">Mobile Number</label>
                  <input
                    type="number"
                    className="border-2 w-full h-10 p-2 rounded-sm focus:outline-none"
                    placeholder="Enter Mobile Number"
                    onChange={(event) => setMobileNumber(event.target.value)}
                    value={mobileNumber}
                    min={0}
                    required={true}
                  />
                </div>
              </div>
              <div className={"grid grid-cols-2 gap-2"}>
                <div className={"mt-4"}>
                  <label htmlFor="">Received Amount</label>
                  <input
                    type="number"
                    className="border-2 w-full h-10 p-2 rounded-sm focus:outline-none"
                    placeholder="Enter Received Amount"
                    onChange={(event) => {
                      remainingBalance(event.target.value);
                    }}
                    value={receivedAmount}
                    required={true}
                  />
                </div>
                <div className={"mt-4"}>
                  <label htmlFor="">Paying Amount</label>
                  <input
                    type="text"
                    className="border-2 w-full h-10 p-2 rounded-sm focus:outline-none"
                    placeholder="Enter Paying Amount"
                    onChange={(event) => {
                      setPayingAmount(event.target.value);
                    }}
                    value={payingAmount}
                    required={true}
                  />
                </div>
              </div>

              <div className={"grid grid-cols-2 gap-2"}>
                <div className={"mt-4"}>
                  <label htmlFor="">Balance Amount</label>
                  <input
                    type="number"
                    className="border-2 w-full h-10 p-2 rounded-sm focus:outline-none bg-gray-100"
                    readOnly={true}
                    value={Math.abs(balance)}
                  />
                </div>
                <div className={"mt-4"}>
                  <label htmlFor="">
                    Select Payment
                    <small className={"text-red-500"}>*</small>
                  </label>
                  <CreatableSelect
                    isClearable
                    options={paymentOptions}
                    onChange={(value) => {
                      setPaymentType(value);
                    }}
                    required={true}
                  />
                </div>
              </div>

              <div className={"mt-4 mb-4"}>
                <label htmlFor="">Payment Note (Optional)</label>
                <textarea
                  className="border-2 w-full p-2 rounded-sm focus:outline-none"
                  placeholder="Enter Payment Note"
                  onChange={(event) => setPaymentNote(event.target.value)}
                  value={paymentNote}
                />
              </div>
              <div>
                <button
                  type={"submit"}
                  className="text-center w-full bg-green-500 text-white focus:outline-none hover:bg-green-600 p-2 disabled:opacity-25"
                  disabled={loader}
                >
                  PLACE ORDER
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </>
  );
};

export default ProceedOrder;
