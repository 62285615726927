import React, { useCallback, useEffect, useState } from "react";
import { ACCOUNT_TYPE, BASE_URL, CART_DATA } from "../constant";
import moment from "moment";
import { Link, useNavigate, useParams } from "react-router-dom";

const MobileHoldOrders = () => {
  const routeParams = useParams();
  const canteenId = routeParams.canteenId;

  const navigate = useNavigate();
  const [orderLoading, setOrderLoading] = useState(false);
  const [orders, setOrders] = useState([]);
  const [mobileNumber, setMobileNumber] = useState("");
  const [modal, setModal] = useState(true);

  const getHoldOrders = useCallback(
    async (e) => {
      e.preventDefault();
      setOrderLoading(true);

      let formData = new FormData();
      formData.append("canteen_id", canteenId);
      formData.append("mobile_number", mobileNumber);

      let requestOptions = {
        method: "POST",
        body: formData,
      };

      const response = await fetch(
        `${BASE_URL}get_hold_orders`,
        requestOptions
      );
      const newData = await response.json();

      setOrders(newData.data);
      setOrderLoading(false);
      setModal(false);
    },
    [canteenId, mobileNumber]
  );

  const saveData = (orderId, value, accountType) => {
    localStorage.removeItem(CART_DATA);
    localStorage.removeItem(ACCOUNT_TYPE);
    localStorage.setItem(CART_DATA, JSON.stringify({ orderId, value }));
    localStorage.setItem(ACCOUNT_TYPE, accountType.toLowerCase());
    setModal(false);
    setMobileNumber("");
    navigate("/?canteenId=" + canteenId);
  };

  if (orderLoading) {
    return (
      <div className="fixed glass w-full h-screen left-0 top-0 z-10 flex flex-wrap justify-center content-center">
        <div className="w-40 bg-white shadow-xl">
          <div className={"p-4"}>
            <img
              src="https://cdn.dribbble.com/users/608059/screenshots/2032455/spinner.gif"
              alt="Loading..."
            />
          </div>
        </div>
      </div>
    );
  }

  return (
    <div>
      <Link to={"/?canteenId=" + canteenId}>
        <div className={"flex items-center"}>
          <i className={"fa fa-arrow-left"}></i>
          &nbsp;
          <p className={"text-lg font-bold text-orange-500"}>Hold Orders</p>
        </div>
      </Link>

      {orders.length > 0 ? (
        <div className="grid grid-cols-1 pb-3">
          {orders.map((order, index) => {
            const products = JSON.parse(order.products);
            return (
              <div
                className="cursor-pointer transition-shadow overflow-hidden border-2 bg-white shadow hover:shadow-lg"
                key={index}
              >
                <div className="p-3 flex justify-between items-center">
                  <p className={"font-bold text-sm"}>
                    {index + 1}) {moment(order.created_date).format("lll")}
                  </p>
                  <p className={"font-bold text-sm"}>
                    Account Type : {order.accountType}
                  </p>
                </div>
                <div className="flex justify-between items-center">
                  <p className={"mx-2 text-xs font-bold text-green-500"}>
                    Phone Number : {order.customer_number}
                  </p>
                  <button
                    className="text-white bg-red-500 text-xs focus:outline-none p-2"
                    onClick={() =>
                      saveData(order.id, products, order.accountType)
                    }
                  >
                    <i className={"fa fa-shopping-bag"}></i> Add To Cart
                  </button>
                </div>
                <p className={"mx-2 mt-2 text-xs font-bold text-orange-500"}>
                  Products
                </p>
                {products.map((val, i) => {
                  return (
                    <div
                      className="w-full text-blue-gray-700 py-2 px-2"
                      key={i}
                    >
                      <p className={"text-xs"}>
                        {i + 1}) {val.product_name}
                      </p>
                      <p className={"text-xs"}>Qty : {val.quantity}</p>
                      <div className="flex justify-between">
                        <p
                          className={
                            "line-through text-red-600 font-bold text-xs"
                          }
                        >
                          Rs. {val.sell_price}
                        </p>

                        <p className={"text-green-600 font-bold text-xs"}>
                          Rs.{" "}
                          {order.accountType === "Abhhyam"
                            ? val.dp_price
                            : val.csp}
                        </p>

                        {order.accountType === "Abhhyam" ? (
                          <p className="nowrap font-bold text-center text-xs">
                            PV : {val.pv}
                          </p>
                        ) : null}
                      </div>
                    </div>
                  );
                })}
                <div className="px-2 flex justify-between">
                  <p className={"font-bold text-xs"}>Total Item :</p>
                  <p className={"font-bold text-xs"}>{order.total_item}</p>
                </div>
                <div className="px-2 flex justify-between">
                  <p className={"font-bold text-xs"}>Total PV :</p>
                  <p className={"font-bold text-xs"}>{order.total_pv}</p>
                </div>
                <div className="px-2 flex justify-between">
                  <p className={"font-bold text-xs"}>Sub Total :</p>
                  <p className={"font-bold text-xs"}>Rs. {order.sub_total}</p>
                </div>
                <div className="px-2 flex justify-between">
                  <p className={"font-bold text-xs"}>Discount :</p>
                  <p className={"font-bold text-xs"}>Rs. {order.discount}</p>
                </div>
                <div className="px-2 flex justify-between">
                  <p className={"font-bold text-xs"}>Tax :</p>
                  <p className={"font-bold text-xs"}>Rs. {order.tax}</p>
                </div>
                <div className="px-2 flex justify-between">
                  <p className={"font-bold text-xs"}>Payable Amount :</p>
                  <p className={"font-bold text-xs"}>Rs. {order.grandTotal}</p>
                </div>
              </div>
            );
          })}
        </div>
      ) : (
        <div className="grid grid-cols-1 pb-3 text-center">
          No orders available on this mobile number
          <button
            type={"submit"}
            className="bg-green-500 text-white focus:outline-none hover:bg-green-600 p-2 disabled:opacity-25 mt-2"
            onClick={() => {
              setModal(true);
              setMobileNumber("");
            }}
          >
            Click here to enter another number
          </button>
        </div>
      )}
      <div
        className="fixed glass w-full h-screen left-0 top-0 z-10 flex flex-wrap justify-center content-center"
        style={{ display: modal ? "flex" : "none" }}
      >
        <div className="w-96 bg-white shadow-xl">
          <div className={"p-4"}>
            <div className={"flex justify-end"}>
              <i className={"fa fa-close"} onClick={() => setModal(false)}></i>
            </div>
            <form onSubmit={getHoldOrders}>
              <div className={"mt-4 mb-4"}>
                <label htmlFor="">Mobile Number</label>
                <input
                  type="number"
                  className="border-2 w-full h-10 p-2 rounded-sm focus:outline-none"
                  placeholder="Enter Mobile Number"
                  onChange={(event) => setMobileNumber(event.target.value)}
                  value={mobileNumber}
                  min={0}
                  required={true}
                />
                <button
                  type={"submit"}
                  className="text-center w-full bg-green-500 text-white focus:outline-none hover:bg-green-600 p-2 disabled:opacity-25 mt-2"
                >
                  SUBMIT
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MobileHoldOrders;
