import { useCallback, useEffect, useState } from "react";
import { BASE_URL } from "../constant";

const useProductFetch = (
  page,
  category,
  searchQuery,
  canteenId,
  searchType
) => {
  const [productLoading, setProductLoading] = useState(false);
  const [productData, setProductData] = useState([]);

  const getProductData = useCallback(async () => {
    setProductLoading(true);

    let formData = new FormData();
    formData.append("canteen_id", canteenId);
    formData.append("limit", "30");
    formData.append("category", category === "all" ? 0 : category);
    formData.append("next", page);
    formData.append("keyword", searchQuery);
    formData.append("searchType", searchType);

    let requestOptions = {
      method: "POST",
      body: formData,
    };

    const response = await fetch(`${BASE_URL}get_canteen_data`, requestOptions);
    const newData = await response.json();

    if (page > 0) {
      if (newData.data.length > 0) {
        setProductData((prevData) => [...prevData, ...newData.data]);
      }
    } else {
      setProductData(newData.data);
    }
    setProductLoading(false);
  }, [canteenId, category, page, searchQuery, searchType]);

  useEffect(() => {
    getProductData();
  }, [getProductData]);

  return [productLoading, productData];
};

export default useProductFetch;
