import { useParams } from "react-router-dom";
import React, { useCallback, useEffect, useRef, useState } from "react";
import AbhhyamInvoice from "../modals/AbhhyamInvoice";
import { INVOICE_DATA } from "../constant";

const AbhhyamInvoicePage = () => {
  const routeParams = useParams();
  const canteenId = routeParams.canteenId;

  const [invoiceData, setInvoiceData] = useState(null);

  const getInvoiceData = useCallback(()=>{
    const data = localStorage.getItem(INVOICE_DATA)
    const parseJson = JSON.parse(data)
  
    setInvoiceData(parseJson)
  },[])

  useEffect(()=>{
    getInvoiceData()
  },[getInvoiceData])

  
 if(invoiceData!==null){
  return (
    <AbhhyamInvoice
      invoiceData={invoiceData}
      canteenId={canteenId}
    />
  );
 }
};
export default AbhhyamInvoicePage;
