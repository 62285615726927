import React, { useRef } from "react";
import moment from "moment";
import { useNavigate } from "react-router-dom";

const Invoice = ({ invoiceData, canteenId }) => {
  const navigate = useNavigate();

  let componentRef = useRef();
  let items = invoiceData.invoiceData.products.length;
  let totalMrp = 0;
  let qty = 0;
  let gst = 0;
  let totalSavings = 0;
  let grandTotal = 0;

  return (
    <div className="fixed glass w-full h-screen left-0 top-0 z-10 flex flex-wrap justify-center content-center">
      <div className=" w-96 bg-white shadow-xl h-full overflow-y-auto">
        <div
          className={"p-4 overflow-y-auto"}
          ref={(el) => (componentRef = el)}
        >
          <div className={"mt-4 mb-4"}>
            <div style={{ textAlign: "right" }}>
              <i
                className={"fa fa-close fa-2x right-0"}
                onClick={() => navigate(`/?canteenId=${canteenId}`)}
              />
            </div>
            <center className={"text-sm"}>
              <img
                src="https://ardhsainikcanteen.com/public/assets/website/images/logo.png"
                alt=""
                className={"img-size"}
              />
              <p className={"font-bold"}>POS BILLING APP</p> <br />
              <p>Ardh Sainik Canteen</p>
              <p>
                OFFICE: B-29, 1st Floor, Pushpanjali Enclave, Pitampura New
                Delhi, Delhi, India-110034
              </p>
              <p>PHONE : 8800001240</p>
            </center>

            <center className={"text-sm mt-5"}>
              <p className={"font-bold"}>SALE BY</p>
              <p className={"font-bold"}>
                {invoiceData.invoiceData.customer_data.canteen_name}
              </p>
              <p>{invoiceData.invoiceData.customer_data.address}</p>
              <p className={"font-bold"}>
                GST NO : {invoiceData.invoiceData.customer_data.gst_number}
              </p>

              <p className={"font-bold mt-5"}>SALE TO</p>
              <p className={"text-xs"}>
                CUSTOMER NUMBER :{" "}
                {invoiceData.invoiceData.customer_data.customer_number}
              </p>
            </center>

            <div className={"border-b-2 mb-1"}></div>
            <div className={"grid grid-cols-2 text-xs"}>
              <p className={"font-bold uppercase"}>
                PAYMENT MODE :{" "}
                {invoiceData.invoiceData.customer_data.payment_mode}
              </p>
              <p className={"font-bold text-right"}>
                DATE :{" "}
                {moment(
                  invoiceData.invoiceData.customer_data.created_at
                ).format("ll")}
              </p>
            </div>
            <div className={"grid grid-cols-2 text-xs"}>
              <p className={"font-bold"}>
                INVOICE No.: {invoiceData.invoiceData.customer_data.invoice_id}
              </p>
              <p className={"font-bold text-right"}>
                TIME :{" "}
                {moment(
                  invoiceData.invoiceData.customer_data.created_at
                ).format("LT")}
              </p>
            </div>

            <div className={"text-xs mt-2"}>
              <div className={"border-dashed border-b-2 mb-1"}></div>
              <p className={"font-bold"} style={{ fontSize: 10 }}>
                ITEM NAME
              </p>
              <div className={"border-b-2 mb-1"}></div>
              <div className={"grid grid-cols-4 text-xs"}>
                <p className={"font-bold"} style={{ fontSize: 10 }}>
                  QTY
                </p>
                <p className={"font-bold"} style={{ fontSize: 10 }}>
                  MRP
                </p>
                <p className={"font-bold"} style={{ fontSize: 10 }}>
                  UNIT PRICE
                </p>
                <p className={"font-bold text-right"} style={{ fontSize: 10 }}>
                  AMOUNT (SAVINGS)
                </p>
              </div>
              <div className={"border-dashed border-b-2 mt-1"}></div>
            </div>

            {invoiceData.invoiceData.products.map((data, index) => {
              let mrp = data.amount;
              let cspQtyAmount = data.csp_amnt * data.quantity;
              let price = data.amount * data.quantity;
              let unitPrice = (cspQtyAmount * 100) / (100 + data.tax_rate);
              let discount = data.discount * data.quantity;
              let taxPriceCal = (unitPrice * data.tax_rate) / 100;
              let taxCal = taxPriceCal / 2;

              qty += data.quantity;
              gst += taxPriceCal;
              totalSavings += discount;
              grandTotal += price - discount;
              totalMrp += mrp * data.quantity;

              return (
                <div className={"text-xs mt-2"}>
                  <p className={"font-bold"} style={{ fontSize: 10 }}>
                    {data.product_name}
                  </p>
                  <div className={"border-b-2 mb-1"}></div>
                  <div className={"grid grid-cols-4 text-xs"}>
                    <p className={"font-bold"} style={{ fontSize: 10 }}>
                      {data.quantity}
                    </p>
                    <p className={"font-bold"} style={{ fontSize: 10 }}>
                      ₹ {mrp}
                    </p>
                    <p className={"font-bold"} style={{ fontSize: 10 }}>
                      ₹ {unitPrice.toFixed(2)}
                    </p>
                    <p
                      className={"font-bold text-right"}
                      style={{ fontSize: 10 }}
                    >
                      ₹ {cspQtyAmount.toFixed(2)} ({discount.toFixed(2)})
                    </p>
                  </div>
                  <div className={"border-b-2 mb-1"}></div>
                  <div className={"grid grid-cols-2 text-xs"}>
                    <p className={"font-bold"} style={{ fontSize: 10 }}>
                      CGST {(data.tax_rate / 2).toFixed(2)}% ( ₹{" "}
                      {taxCal.toFixed(2)})
                    </p>
                    <p
                      className={"font-bold text-right"}
                      style={{ fontSize: 10 }}
                    >
                      SGST {(data.tax_rate / 2).toFixed(2)}% ( ₹{" "}
                      {taxCal.toFixed(2)})
                    </p>
                  </div>
                  <div className={"border-dashed border-b-2 mt-1"}></div>
                </div>
              );
            })}

            <center className={"text-xs"}>
              <p>
                Items/Qty : {items}/{qty}
              </p>
              <p>GST : ₹ {gst.toFixed(2)}</p>
              <p className={"font-bold"}>TOTAL MRP : ₹ {totalMrp.toFixed(2)}</p>
              <p className={"font-bold"}>
                TOTAL SAVINGS : ₹ {totalSavings.toFixed(2)}
              </p>
              <p className={"font-bold"}>
                TOTAL AMOUNT : ₹ {grandTotal.toFixed(2)}
              </p>
            </center>
            <div className={"border-dashed border-b-2 mt-1"}></div>
            <p className={"font-bold text-center text-xs mt-1"}>
              THANK YOU FOR SHOPPING WITH US
            </p>
            <div className={"border-dashed border-b-2 mt-1"}></div>
            <p className={"font-bold text-center text-xs mt-1 uppercase"}>
              Thanks for supporting soldiers. Country first
            </p>
            <p className={"font-bold text-center text-xs mt-1 uppercase"}>
              Initiative of Ardh Sainik Welfare Trust (Regd)
            </p>
            <p className={"font-bold text-center text-xs mt-1 uppercase"}>
              CALL US - 8800001240/50/60
            </p>
            <p className={"font-bold text-center text-xs mt-1"}>
              www.ardhsainikcanteen.in
            </p>
            <p className={"font-bold text-center text-xs mt-1"}>
              || जय हिन्द ||
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Invoice;
